<template>
  <div>
    <NotificationFilter @apply="getAllNotificationsList" />
    <NotificationReminderContainer
      :update-pagination="updatePagination"
      :is-loading-component="isLoadingComponent"
      :notification-actions="notificationActions"
      @update="typeAction"
      @filterRecord="filterRecord"
    />
    <!-- SCHEDULED NOTIFICATION -->
    <ScheduledNotificationCreateUpdate
      v-if="scheduleModel"
      :show-model="scheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :concerned="currentActionObject.concernedList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      @handleClick="toogleScheduleModel"
      @update="filterRecord"
    />
    <!-- UNSCHEDULED NOTIFICATION -->
    <UnScheduledNotificationCreateUpdate
      v-if="unScheduleModel"
      :show-model="unScheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      module="section_teacher"
      @handleClick="toggleUnScheduleModel"
      @update="updateNotificationsList"
      @addNotificationAlert="addNotification"
    />
  </div>
</template>

<script>
import ScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/AddEditNotificationAlertTemplate1.vue'
import UnScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/CreateUnScheduleNotification.vue'
import NotificationReminderContainer from '@/src/components/Settings/notifications/NotificationReminderContainer.vue'
import { paginationRangeHandler } from '@components/BaseComponent/pagination.util.js'
import NotificationFilter from '@views/settings/notifications/attendance/campus/CampusAttendanceNotificationFilters.vue'
import NOTIFICATION_PATHS, {
  NOTIFICATION_RECIEVERS,
} from '@src/constants/notification-constants.js'
/* Utils or mixins */
import { mapActions } from 'vuex'
import notificationMixin from '@src/mixins/notification-mixin.js'
import { getNotificationsOptionsList } from '@utils/notifications'
export default {
  components: {
    ScheduledNotificationCreateUpdate,
    UnScheduledNotificationCreateUpdate,
    NotificationReminderContainer,
    NotificationFilter,
  },
  mixins: [notificationMixin],
  data() {
    return {
      dataPayload: {
        payload: '',
        path: NOTIFICATION_PATHS.TEACHER,
      },
      optionList: {
        create_teacher: [NOTIFICATION_RECIEVERS.SPECIFIC_STAFF],
        edit_teacher: [
          NOTIFICATION_RECIEVERS.SPECIFIC_STAFF,
          NOTIFICATION_RECIEVERS.CONCERNED_TEACHER,
        ],
        section_teacher_enrollment: [
          NOTIFICATION_RECIEVERS.SPECIFIC_STAFF,
          NOTIFICATION_RECIEVERS.CONCERNED_TEACHER,
        ],
      },
    }
  },

  methods: {
    setActionList() {
      this.notificationActions = {
        create_teacher: {
          title: 'Reminder for Create Teacher',
          label: 'Create Teacher',
          feature: 'section_teacher',
          action: 'create_teacher',
          loading: false,
          count: 0,
        },
        edit_teacher: {
          title: 'Reminder for Edit Teacher',
          label: 'Edit Teacher',
          feature: 'section_teacher',
          action: 'edit_teacher',
          loading: false,
          count: 0,
        },
        section_teacher_enrollment: {
          title: 'Reminder for Enroll Teacher',
          label: 'Edit Teacher',
          feature: 'section_teacher',
          action: 'section_teacher_enrollment',
          loading: false,
          count: 0,
        },
      }
    },
    setConcernedAndOptionsList() {
      Object.keys(this.optionList).forEach((option) => {
        this.notificationActions[option].optionsList = getNotificationsOptionsList(
          this.optionList[option],
        )
      })
    },

    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'NOTIFICATION_REMINDER',
          buttons: [
            {
              title: 'TEACHER_NOTIFICATIONS',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'CREATE_TEACHER',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.create_teacher
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },

                {
                  title: 'EDIT_TEACHER',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.edit_teacher
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'ENROLL_TEACHER',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.section_teacher_enrollment
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },

    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>
